import * as React from "react"
import Homzhub from "../images/homzhub.png"
import { HeaderClass } from "./header.css"
import Switch from "./switch"

const Header = () => (
  <header className="HeaderClass">
    <div>
      <img src={Homzhub} alt="Homzhub" />
      <h3 style={{ margin: 0 }}>Homzhub</h3>
      <a href="https://www.homzhub.com/" target="_blank" rel="noreferrer">
        www.homzhub.com
      </a>
      <Switch />
    </div>
  </header>
)

export default Header
