import React from "react"
import { buttonGroup, active } from "./switch.css"
import { Link } from "gatsby"

function Switch() {
  return (
    <div className="buttonGroup">
      <Link to="/" activeClassName="active">
        Web
      </Link>
      <Link to="/android" activeClassName="active">
        Android
      </Link>
      <Link to="/ios" activeClassName="active">
        iOS
      </Link>
    </div>
  )
}

export default Switch
